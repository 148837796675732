import React from 'react';
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const Contact = () => {
  return (
    <article className="wrapper">
      <NavBar current="contact"/>
      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <div className="cards w-full">
          <h1 className="text-2xl">Kontakt</h1>

          <p>Gerne stehen wir Ihnen mit Rat und Tat zur Verfügung. Über eine Kontaktaufnahme ihrerseits würden wir uns
            freuen.</p>

          <p>Über die unten stehende Adresse können Sie uns jederzeit kontaktieren. Egal ob telefonisch schriftlich oder
            per E-Mail.</p>

          <br/>
          <h2 className="text-xl">Unsere Adresse</h2>
          <p>Mobility Leasing GmbH</p>
          <p>Europastrasse 5</p>
          <p>A-6322 Kirchbichl</p>

          <p>Telefon: 0043-5372-64993 </p>
          <p>ATU 63395659</p>

          <br/>
          <a href="mailto:info@moblity-leasing.at" className="text-blue-500 underline">info@moblity-leasing.at</a>
        </div>
      </div>
      <Footer/>
    </article>
  );
};

export default Contact;